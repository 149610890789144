.navbar {
	width: 100vw;
	max-width: 2500px;
	height: var(--nav_height);
	padding:  2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fffc;
	padding: 0.5rem 1.57rem;
	background: #1C1B1F;
	// box-shadow: 0px 6.266px 6.266px 1.566px rgba(109, 141, 173, 0.15);
	position: fixed;
	z-index: 10;
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	top: 0;


	@media screen and (min-width: 730px) {
		// padding: 0 2.33rem 0 2.33rem;
		padding: 0.40288rem 4.36894rem 0.35175rem 4.36894rem;
	}

	@media screen and (min-width: 1000px) {
		// padding: 0.49rem 2.87rem 0.48rem 2.87rem;
		padding: 0.49469rem 5.36431rem 0.48331rem 5.36425rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 0.61rem 12.19rem 0.52rem 12.19rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 0.81rem 16.25rem 0.75rem 16.25rem;
	}
}
.camera_logoContainer {
	display: flex;
	align-items: center;

	a{
		display: flex;
		align-items: center;
	
		.camera_logo {
			height: 1.6rem;
		
			@media screen and (min-width: 730px) {
			}
		
			@media screen and (min-width: 1000px) {
				height: 1.8rem
			}
		
			@media screen and (min-width: 1400px) {
				height: 2.3rem;
			}
		
			@media screen and (min-width: 1700px) {
				height: 2.5rem;
			}
		}
	}
}

.downloadBttnContainer {
	display: flex;

	a{
		display: flex;
		align-items: center;
	
		.downloadBttn {
			border: none;
			background-color: #4359f6;
			outline: none;
			color: #ffffff;
			font-size: 0.875rem;
			font-weight: 700;
			padding: 0.5rem 1.25rem;
			border-radius: 1.54rem;
			cursor: pointer;
			font-family: 'Plus Jakarta Sans';
		
			@media screen and (min-width: 730px) {
				padding: 0.52rem 0.99rem;
				font-size: 0.68rem;
			}
		
			@media screen and (min-width: 1000px) {
				padding: 0.6rem 1.22rem;
				font-size: 0.837rem;
				border-radius: 1.9rem;
			}
		
			@media screen and (min-width: 1400px) {
				padding: 0.7rem 1.5rem;
				font-size: 1.03rem;
				border-radius: 2.3rem;
			}
		
			@media screen and (min-width: 1700px) {
				padding: 1rem 2rem;
				font-size: 1.375rem;
				border-radius: 3.125rem;
			}
		}
	}
}

